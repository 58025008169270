import React from 'react';
import styled from 'styled-components';
import { ButtonVariants, getButtonStyling } from '../Button/styling';

const Link = styled.a<{ $variant: ButtonVariants; $fullWidth?: boolean }>`
  ${({ $variant, $fullWidth }) => getButtonStyling({ $variant, $fullWidth })}
`;

type Props = {
  children: React.ReactNode;
  href: string;
  variant: ButtonVariants;
  fullWidth?: boolean;
  className?: string;
  target?: string;
};

const LinkButton = ({ children, href, variant, fullWidth, className, target }: Props) => (
  <Link href={href} $variant={variant} $fullWidth={fullWidth} className={className} target={target}>
    {children}
  </Link>
);

export default LinkButton;

import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { LayoutData, PageTranslationLink } from '../../lib/menus';
import Header from '../Header';
import Footer from './Footer';

const Layout = ({
  children,
  leftMenu,
  rightMenu,
  footerMenu,
  footerBottomMenu,
  footerIcons,
  currentLocale,
  socialMediaLinks,
  renderLanguageSwitcher = true,
  headCode,
  endOfBodyCode,
  pageTranslationLinks,
}: LayoutData & {
  children: React.ReactNode;
  currentLocale?: {
    locale: string;
    label: string;
  };
  renderLanguageSwitcher?: boolean;
  pageTranslationLinks?: PageTranslationLink[];
}) => {
  const { t } = useTranslation();
  return (
    <>
      {headCode ? (
        <Head>
          {/* 
            hack to get around React's forced wrapper for dangerouslySetInnerHTML
             @see https://github.com/facebook/react/issues/12014#issuecomment-434534770
          */}
          <style dangerouslySetInnerHTML={{ __html: `</style>${headCode}<style>` }} />
        </Head>
      ) : null}
      <Header
        leftMenuLinks={leftMenu}
        rightMenuLinks={rightMenu}
        currentLocale={currentLocale}
        renderLanguageSwitcher={renderLanguageSwitcher}
        pageTranslationLinks={pageTranslationLinks}
      />
      <main>{children}</main>
      <Footer
        copyrightText={t('footer.allRightsReserved')}
        footerLinks={footerMenu}
        footerBottomLinks={footerBottomMenu}
        footerIcons={footerIcons}
        socialMediaLinks={socialMediaLinks}
      />
      <div dangerouslySetInnerHTML={{ __html: endOfBodyCode || '' }} />
    </>
  );
};

export default Layout;

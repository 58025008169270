import { useMediaQuery } from '@mnd-frontend/hooks';
import { MynewsdeskLogo, media } from '@mnd-frontend/ui';
import styled from 'styled-components';
import { SrOnly } from '../SrOnly';

const Header = styled.header<{ $isMobile: boolean }>`
  width: 100%;
  background: var(--bg-color);
  position: sticky;
  top: 0;
  z-index: 100;
  padding: ${({ $isMobile }) =>
    $isMobile
      ? 'var(--spacing-small)'
      : 'var(--spacing-medium) min(var(--spacing-medium), var(--site-padding))'};
`;

const CustomTourHeader = () => {
  const isMobile = useMediaQuery(media.lessThan('normal'));
  return (
    <Header $isMobile={isMobile}>
      <a href="https://mynewsdesk.com">
        <MynewsdeskLogo width="162" height="28" aria-hidden="true" />
        <SrOnly>Mynewsdesk</SrOnly>
      </a>
    </Header>
  );
};

export default CustomTourHeader;

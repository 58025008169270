import Head from 'next/head';
import CustomTourHeader from '../CustomTourHeader';

export const CustomTourLayout = ({
  children,
  headCode,
  endOfBodyCode,
  currentLocale,
}: {
  children: React.ReactNode;
  headCode?: string;
  endOfBodyCode?: string;
  currentLocale?: { locale: string; label: string };
}) => {
  return (
    <>
      <Head>
        {/* 
            hack to get around React's forced wrapper for dangerouslySetInnerHTML
            @see https://github.com/facebook/react/issues/12014#issuecomment-434534770
            */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="robots" content="noindex" />
        <title>Product Tour | Mynewsdesk</title>
        {headCode && <style dangerouslySetInnerHTML={{ __html: `</style>${headCode}<style>` }} />}
      </Head>
      <CustomTourHeader />
      {children}
      {endOfBodyCode && <div dangerouslySetInnerHTML={{ __html: endOfBodyCode || '' }} />}
    </>
  );
};

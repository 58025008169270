import Image from 'next/image';
import styled, { css } from 'styled-components';
import bgImage from '../../assets/img/bg-img.png';

const Wrapper = styled.div<{ $backgroundImage: boolean }>`
  padding: var(--section-padding) var(--site-padding);
  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
      position: relative;
    `}
  background: ${props =>
    props.$backgroundImage ? 'none' : `linear-gradient(rgb(24, 22, 70) 0%, rgb(78, 73, 199) 100%)`};
`;

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-large);
  max-width: 1100px;
  margin: auto;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

export const PageLayout = ({
  children,
  backgroundImage = false,
}: {
  children: React.ReactNode;
  backgroundImage?: boolean;
}) => {
  return (
    <Wrapper $backgroundImage={backgroundImage}>
      {backgroundImage && (
        <Image
          src={bgImage}
          alt=""
          role="presentation"
          aria-hidden="true"
          placeholder="blur"
          fill
          style={{ objectFit: 'cover' }}
        />
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
